import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import tw from 'tailwind.macro'
import { colors } from '../../tailwind'

const Banner = styled.div`
  ${tw`fixed z-50 left-0 h-16 text-center w-full font-sans text-xs md:text-sm p-2 flex items-center justify-center`}
  bottom: 0;
  background-color: ${colors.white};
`

const Button = styled.button`
  ${tw`border-0 px-4 py-3 ml-4 cursor-pointer hover:opacity-75`}
  color: ${colors.white};
  background-color: ${colors['teal-dark']};
`
function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toGMTString()}`
  } else {
    expires = ''
  }
  document.cookie = `${name}=${value}${expires}; path=/`
}

function readCookie(name) {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function hasConsent() {
  return readCookie('consent')
}

const CookieConsent = () => {
  const [consent, setConsent] = useState(true)
  useEffect(() => setConsent(hasConsent()), [])

  function cookieOk() {
    createCookie('consent', 'true', 60)
    setConsent(true)
  }
  return (
    <>
      {!consent && (
        <Banner>
          <span>En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies pour réaliser des statistiques de visites anonymes.</span>
          <Button type="button" onClick={cookieOk}>
            Je suis informé
          </Button>
        </Banner>
      )}
    </>
  )
}

export default CookieConsent
