import PropTypes from 'prop-types'
import React from 'react'
import bulb from '../images/svg/light-bulb.svg'
import diamond from '../images/svg/diamond.svg'
import festival from '../images/svg/festival.svg'
import game from '../images/svg/game.svg'
import { hidden } from '../styles/utils'
import locator from '../images/svg/locator.svg'
import message from '../images/svg/message.svg'
import styled from 'styled-components'
import time from '../images/svg/time.svg'
import tw from 'tailwind.macro'
import { width as twWidth } from '../../tailwind'

const Wrapper = styled.svg`
  ${tw`absolute`};
  stroke: currentColor;
  ${props => props.hiddenMobile && hidden};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`
const Image = styled.img`
  ${tw`absolute`};
  left: ${props => props.left};
  top: ${props => props.top};
  width: ${props => props.svgWidth};
`

const icons = {
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: '0 0 30 30',
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: '0 0 30 30',
  },
  arrowUp: {
    shape: (
      <>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />{' '}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </>
    ),
    viewBox: '0 0 30 42',
  },
  upDown: {
    shape: (
      <>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </>
    ),
    viewBox: '0 0 30 44.58',
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: '0 0 30 30',
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: '0 0 30 30',
  },
  cross: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
    viewBox: '0 0 100 100',
  },
  face_1: {
    shape: (
      <>
        <path d="M210.6 195c5.2-10.4 1-21.9-9.4-27.1-37.5-17.7-78.2-1-80.3 0-10.4 5.2-14.6 16.7-10.4 27.1 5.1 13.9 20.7 13.4 27.1 9.4 0 0 25-10.4 45.9 0 10.4 5.2 21.9 1 27.1-9.4z" />
        <path d="M245 0C109.5 0 0 109.5 0 245s109.5 245 245 245 245-109.5 245-245S380.5 0 245 0zm0 449.3c-112.6 0-204.3-91.7-204.3-204.3S132.4 40.7 245 40.7 449.3 132.4 449.3 245 357.6 449.3 245 449.3z" />
        <path d="M369.1 167.9c-37.5-17.7-78.2-1-80.3 0-10.4 5.2-14.6 16.7-10.4 27.1 5.1 13.9 20.7 13.4 27.1 9.4 0 0 25-10.4 45.9 0 10.4 5.2 21.9 1 27.1-9.4 5.2-10.5 1-21.9-9.4-27.1zM270.9 330.7s-28.4 10.4-52 0c-11.8-5.2-24.8-1-30.7 9.4s-1.2 21.9 10.6 27.1c42.6 17.7 88.7 1 91 0 11.8-5.2 16.6-16.7 11.8-27.1-5.7-13.9-23.5-13.4-30.7-9.4z" />
      </>
    ),
    viewBox: '0 0 490 490',
  },
  face: {
    shape: (
      <>
        <path d="M245 0C109.5 0 0 109.5 0 245s109.5 245 245 245 245-109.5 245-245S380.5 0 245 0zm0 449.3c-112.6 0-204.3-91.7-204.3-204.3S132.4 40.7 245 40.7 449.3 132.4 449.3 245 357.6 449.3 245 449.3z" />
        <path d="M370.1 172h-18.8v-17.7c0-11.5-9.4-20.9-20.9-20.9s-20.9 9.4-20.9 20.9V172H293c-11.5 0-20.9 9.4-20.9 20.9s9.4 20.9 20.9 20.9h16.7v17.7c0 11.5 9.4 20.9 20.9 20.9s20.9-9.4 20.9-20.9v-17.7h18.8c10.4 0 19.8-9.4 20.9-20.9-.2-11.5-9.6-20.9-21.1-20.9zM180.4 231.4v-17.7h17.7c11.5 0 19.8-9.4 19.8-20.9S208.5 172 197 172h-16.7v-17.7c0-11.5-9.4-20.9-20.9-20.9s-20.9 9.4-20.9 20.9V172h-18.8c-11.5 0-20.9 9.4-20.9 20.9s9.4 20.9 20.9 20.9h18.8v17.7c0 11.5 9.4 20.9 20.9 20.9 10.5-.1 19.9-9.5 21-21zM299.2 324.2c-29.2 30.2-78.2 30.2-108.4 0-8.3-8.3-20.9-8.3-29.2 0s-8.3 20.9 0 29.2c22.9 22.9 53.2 34.4 83.4 34.4s60.5-11.5 83.4-34.4c8.3-8.3 8.3-20.9 0-29.2s-20.8-8.3-29.2 0z" />
      </>
    ),
    viewBox: '0 0 490 490',
  },
  diamond,
  festival,
  locator,
  game,
  message,
  bulb,
  time,
}

const SVG = ({ stroke, fill, width, icon, left, top, hiddenMobile, file }) => (
  <>
    {file ? (
      <Image
        src={icons[icon]}
        svgWidth={twWidth[`${width}`]}
        left={left}
        top={top}
        alt=""
      />
    ) : (
      <Wrapper
        viewBox={icons[icon].viewBox}
        stroke={stroke}
        fill={fill}
        svgWidth={twWidth[`${width}`]}
        left={left}
        top={top}
        hiddenMobile={hiddenMobile}
      >
        {icons[icon].shape}
      </Wrapper>
    )}
  </>
)

export default SVG

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  hiddenMobile: PropTypes.bool,
  file: PropTypes.bool,
}

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  fill: 'none',
  left: '0%',
  top: '0%',
  hiddenMobile: false,
  file: false,
}
