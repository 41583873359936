import React from 'react'
import styled, { css } from 'styled-components'

const sizes = {
  huge: css`
    height: 400px;
  `,
  middle: css`
    height: 200px;
  `,
  normal: css`
    height: 100px;
  `,
  small: css`
    height: 65px;
  `,
  tiny: css`
    height: 20px;
  `,
  'max-fluid': css`
    width: 100%;
    max-width: 500px;
    padding: 0 20px;
  `,
}

const Bard = styled.svg`
  ${p => sizes[p.size]}
`

const Logo = props => {
  const { color, size } = props
  return (
    <Bard
      {...props}
      viewBox={size === 'tiny' ? '0 0 96 30' : '0 0 96 37'}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      {size !== 'tiny' && (
        <g fontFamily="'ArialMT','Arial',sans-serif" fontSize="4.962">
          <text fill={color} x="266.941" y="229.477" transform="translate(-249.672 -194.103)">
            M
          </text>
          <text fill={color} x="271.897" y="229.477" transform="translate(-249.672 -194.103)">
            A
          </text>
          <text fill={color} x="276.007" y="229.477" transform="translate(-249.672 -194.103)">
            S
          </text>
          <text fill={color} x="279.916" y="229.477" transform="translate(-249.672 -194.103)">
            T
            <tspan x="283.647" y="229.477">
              E
            </tspan>
          </text>
          <text fill={color} x="287.657" y="229.477" transform="translate(-249.672 -194.103)">
            R
          </text>
          <text fill={color} x="294.029" y="229.477" transform="translate(-249.672 -194.103)">
            Y
          </text>
          <text fill={color} x="297.639" y="229.477" transform="translate(-249.672 -194.103)">
            O
          </text>
          <text fill={color} x="301.848" y="229.477" transform="translate(-249.672 -194.103)">
            U
          </text>
          <text fill={color} x="306.132" y="229.477" transform="translate(-249.672 -194.103)">
            R
          </text>
          <text fill={color} x="312.504" y="229.477" transform="translate(-249.672 -194.103)">
            G
          </text>
          <text fill={color} x="316.664" y="229.477" transform="translate(-249.672 -194.103)">
            A
          </text>
          <text fill={color} x="320.723" y="229.477" transform="translate(-249.672 -194.103)">
            M
          </text>
          <text fill={color} x="325.73" y="229.477" transform="translate(-249.672 -194.103)">
            E
          </text>
        </g>
      )}
      <path
        fill={color}
        d="M23.18 20.76c0 5.211-3.193 7.82-9.581 7.82H3.283V0h9.58c6.14 0 9.21 2.497 9.21 7.494 0 1.338-.296 2.578-.898 3.725-.656 1.227-1.558 2.087-2.704 2.581 1.475.437 2.65 1.321 3.522 2.66.79 1.281 1.188 2.713 1.188 4.3zM20.029 7.493c0-3.658-2.386-5.487-7.165-5.487H5.37v11.055h7.78c4.583 0 6.877-1.857 6.877-5.568zm1.104 13.265c0-3.902-2.51-5.855-7.533-5.855H5.37v11.63h8.228c5.024 0 7.533-1.926 7.533-5.775zM49.218 28.58h-2.211l-3.358-8.724H30.344l-3.36 8.724h-2.212L35.707 0h2.58l10.93 28.58zm-6.305-10.686L36.977 2.375l-5.896 15.52h11.832zM71.868 28.58h-2.414l-7.737-8.043a1.305 1.305 0 0 0-.291-.227 3.341 3.341 0 0 0-1.677-.444h-4.954v8.714h-2.088V0h9.58c2.732 0 4.863.59 6.386 1.76 1.68 1.303 2.529 3.456 2.539 6.076v4.334c0 2.344-.667 4.166-2.003 5.465-1.341 1.298-3.195 2.028-5.57 2.191l8.229 8.754zm-2.703-16.41V7.823c-.027-3.69-2.321-5.735-6.877-5.735h-7.493V17.86h7.493c4.588 0 6.877-1.898 6.877-5.69zM78.313 28.58L71.826.002h8.163l.253 2.046h-5.865l5.552 24.443h1.22l-.097 2.09h-2.74z"
        fillRule="nonzero"
      />
      <path fill={color} d="M39.389 17.943v1.893h17.345v-1.893z" />
      <path
        fill={color}
        d="M80.306 26.493c3.142 0 5.44-.437 6.906-1.31 1.325-.819 2.214-2.172 2.668-4.053.279-1.148.51-3.428.686-6.838.178-3.41.186-5.692.03-6.838-.254-1.913-1.001-3.264-2.242-4.055-1.376-.9-3.627-1.35-6.767-1.35h-3.852l.084-2.047h3.875c3.41 0 5.912.44 7.506 1.31 2.015 1.147 3.172 3.264 3.475 6.347.14 1.473.132 3.686-.022 6.633-.154 2.95-.377 5.145-.67 6.592-.624 3.083-2.006 5.198-4.142 6.347-1.685.901-4.217 1.35-7.604 1.35l.069-2.088z"
        fillRule="nonzero"
      />
      <path
        d="M80.425 8.17l2.291 10.108M81.867 8.17l2.29 10.108M83.387 8.17l2.289 10.108"
        fill="none"
        stroke={color}
        strokeWidth=".35"
      />
      {size !== 'tiny' && (
        <path
          d="M3.01 33.545h9.495M83.752 33.545h9.172"
          fill="none"
          stroke={color}
          strokeWidth=".6"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
      )}
    </Bard>
  )
}

export default Logo
