import React from 'react'
import GlobalStyle from '../styles/global'
import SEO from './SEO'

const UtilsHead = () => (
  <>
    <SEO />
    <GlobalStyle />
  </>
)

export default UtilsHead
