import React from 'react'
import PropTypes from 'prop-types'
import { ScrollingProvider } from 'react-scroll-section'
import UtilsHead from './UtilsHead'

const Layout = ({ children }) => (
  <>
    <UtilsHead />
    <ScrollingProvider>{children}</ScrollingProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
