module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Bard', // Navigation and Site Title
  siteTitleAlt: 'Bard', // Alternative Site title for SEO
  siteTitleShort: 'Bard', // short_name for manifest
  siteHeadline: 'Système de gestion de salle novateur et efficace.', // Headline for schema.org JSONLD
  siteUrl: 'https://www.bardsystems.com', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/logo-bard.jpg', // Used for SEO and manifest
  siteDescription: 'Bard, le système de gestion de salle novateur et efficace.',
  author: '@bulcub', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@cara', // Twitter Username
  ogSiteName: 'Bard', // Facebook Site Name
  ogLanguage: 'fr_FR', // Facebook Language
  googleAnalyticsID: 'UA-143950314-1',

  // Manifest and Progress color
  themeColor: '#ffffff',
  backgroundColor: '#000000',
}
