import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { useStaticQuery, graphql } from 'gatsby'
// import GatsbyLogo from './Logo/Gatsby.svg'

import Content from '../elements/Content'
import { UpDown, UpDownWide, waveAnimation } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from './SVG'

import { Divider } from '../elements/Dividers'

const WaveWrapper = styled.div`
  ${tw`absolute pin-b w-full`};
  transform: matrix(1, 0, 0, -1, 0, 0);
`

const InnerWave = styled.div`
  ${tw`relative h-full`};
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    ${waveAnimation('20s')};
  }
`

const Text = styled.p`
  ${tw`text-center`}
  color: ${colors.yellow};
`

// const FooterContainer = styled.footer`
//   padding: 1em;
//   background: black;
//   color: white;
//   display: flex;
//   flex: 0 1 auto;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `

const RenponsiveLogo = styled.img`
  width: 100px;
  height: 25px;

  @media (min-width: 400px) {
    width: 150px;
    height: 35px;
  }
`

const Logo = ({ url, logo, alt = '' }) => (
  <>
    <a href={url} rel="noopener noreferrer" target="_blank">
      <RenponsiveLogo src={logo} alt={alt} />
    </a>
  </>
)

Logo.propTypes = {
  url: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  alt: PropTypes.string,
}
Logo.defaultProps = {
  alt: 'Logo',
}

const Footer = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "footer" } }) {
          html
        }
      }
    `
  )
  return (
    <footer>
      <Divider fill={colors.red} speed={0.2} offset={offset}>
        <WaveWrapper>
          <InnerWave>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
              <path>
                <animate attributeName="d" values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z" repeatCount="indefinite" dur="30s" />
              </path>
            </svg>
          </InnerWave>
        </WaveWrapper>
      </Divider>
      <Content speed={0.4} offset={offset}>
        <Text
          dangerouslySetInnerHTML={{
            __html: data.markdownRemark.html,
          }}
        />
      </Content>
      <Divider speed={0.1} offset={offset}>
        <UpDown>
          <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
          <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
          <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
        <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
      </Divider>
    </footer>
  )
}

export default Footer
